interface ClinicalParadigmProps {
  text: string
  image: string
}

const Component = (props: ClinicalParadigmProps) => {

  return(
    <div className="clinical-paradigm-card">
      <div className="card-image"><img src={props.image} alt="" /></div>
      <div className="content"><p>{props.text}</p></div>
    </div>
  )

}

export default Component