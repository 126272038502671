import { useEffect } from "react"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import jsonp from "jsonp"
import AOS from "aos"

import { Formik, Form } from "formik"
import { ROUTES } from "../../constants/routes"
import Header from "../../components/layout/header"
import Footer from "../../components/layout/footer"
import ClinicalParadigmCard from "../../components/cards/clinicalParadigm"
import DiagnosisCard from "../../components/cards/diagnosisArray"
import { MixpanelTracking } from "../../services/Mixpanel"


import videoUrl from "../../assets/videos/rna_molecule.mp4"
import misionVisionImage from "../../assets/images/webp/cellRootsImageLatest.webp"
import treatmentPlan from "../../assets/images/png/treatment_plan.png"
import safelyProtocol from "../../assets/images/png/safely_protocol.png"
import bloodBasedAssay from "../../assets/images/png/blood_based_assay.png"
import patientPlan from "../../assets/images/png/patient_plan.png"
import urologicalsCancer from "../../assets/images/png/urological_cancer.png"
import impactfull from "../../assets/images/svg/icon_impact_new.svg"
import empowering from "../../assets/images/svg/icon_empowering_new.svg"
import lifeChanging from "../../assets/images/svg/icon_life_changing_new.svg"

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
}

const ImmunisLandingPageComponent = () => {

  useEffect(() => {
    MixpanelTracking.getInstance().HomePage();

  AOS.init({
    once: true,
  });
  AOS.refresh();
}, []);

  const ClinicalParadigmData = [
    { text: "Making Active Surveillance a BETTER component of the treatment plan", image: treatmentPlan },
    { text: "SAFELY remain or begin on an Active Surveillance protocol", image: safelyProtocol },
    { text: "First non-invasive blood-based assay for Active Surveillance validated on Thousands of patients", image: bloodBasedAssay },
    { text: "Will improve physician and patient adherence to established Active Surveillance Guidelines", image: patientPlan },
    { text: "Initial test is for Prostate Cancer with future tests in other urological cancers", image: urologicalsCancer },
  ]

  const ValidationSchema = Yup.object().shape({
    FNAME: Yup.string().required("First name is required"),
    LNAME: Yup.string().required("Last name is required"),
    EMAIL: Yup.string().required("Email is required")
  });

  const handleFormSubmit = (values: any, actions: any) => {
    const url = "https://immunis.us21.list-manage.com/subscribe/post-json?u=2b572b065e617a9e9f9b1f944&amp;id=970db65c77&amp;f_id=0025dfe6f0";


    jsonp(`${url}&EMAIL=${values.EMAIL}&FNAME=${values.FNAME}&LNAME=${values.LNAME}&INQUIRY=${values.INQUIRY}&TITLE=${values.TITLE}&ORG=${values.ORG}&PHONE=${values.PHONE}`, { param: 'c' }, (_:any, data:any) => {
      const { msg, result } = data;

      if(result !== 'error') {
        actions.resetForm({values: ''});
        MixpanelTracking.getInstance().SubscribedToNewsletter(values.EMAIL);
      }

      alert(msg);
    });
  }

  return (
    <>
      <Header />
      <div className="col-12 home-page">
        <div className="home-wrapper">
          <video autoPlay={true} muted loop>
            <source src={videoUrl} type="video/mp4" />
          </video>
          <div className="flex col-12 col-lg-6 px-page" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="2000">
            <div className="home-content d-flex flex-column justify-content-center">
              <h1 className="text-align-center">Revolutionary <br /><span className="text-gradient">Liquid biopsy solution</span></h1>
              <p className="text-big my-4">Real-time active surveillance and early detection assays</p>
              <Link data-aos="fade-up" data-aos-easing="ease-out" data-aos-duration="2500" className="btn cta mt-4" to={ROUTES.immunisAISolution}>Learn more</Link>
            </div>
          </div>
        </div>
        <div className="mision-vision-wrapper d-flex flex-lg-row flex-wrap justify-content-around align-items-around px-page border-down"  data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
          <div className="col-lg-6 col-12">
            <img src={misionVisionImage} alt="cellRootsImageLatest" />
          </div>
          <div className="col-lg-6 col-12 d-flex flex-column justify-content-center">
            <p><b>Immunis.AI</b> is a privately held company committed to making a global impact by empowering patients and their physicians with actionable information for disease management.</p>
            <br />
            <p>Our patented platform leverages the body’s own immune defense system, state of the art analytical methods, and AI / machine learning to provide unique real-time insights into a patient’s disease.</p>
            <br />
            <p>Our vision is to leverage our validated platform to deliver a suite of non-invasive, blood-based, assays that can have broad use in active surveillance and early detection.</p>
            <br />
            <p>Our mission is to provide tools for physicians and patients that can be used to more accurately make critical decisions on treatment plans for patients in the most comfortable and cost efficient manner.</p>
          </div>
        </div>
        <div className="clinical-paradigm-wrapper p-page" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="2000">
          <h2 className="text-yellow">Active survilleance test</h2>
          <div className="grid lg:grid-cols-3 lg:gap-5 md:grid-cols-2 md:gap-4 sm:grid-cols-1 gap-4 justify-center">
            {ClinicalParadigmData.map((data, _) => (
              <ClinicalParadigmCard text={data.text} image={data.image} key={_} />
            ))}
          </div>
          <hr />
          <h4 className="text-yellow">Compared to the current clinical paradigm</h4>
          <ul>
            <li className="mb-4">MORE aggressive cancer will be found</li>
            <li >FEVER indolent cancers will be treated and will remain on Active Surveillance</li>
          </ul>
        </div>
        <div className="diagnostic-assays-wrapper p-page" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="2000">
          <h2 className="text-yellow">Immunis.AI diagnostic assays are</h2>
          <div className="md:grid md:grid-cols-3 gap-0">
            <DiagnosisCard 
              heading="Impactful"
              img={impactfull}
              content="We will improve the human condition globally by combining the power of the 
            immune system and our proprietary platform, to deliver actionable medical information."
            ></DiagnosisCard>
            <DiagnosisCard 
              heading="Empowering"
              img={empowering}
              content="We will empower patients with deeper insights into their current health and importantly, 
            the opportunity for making critical decisions to move to more agressive treatment, 
            when disease burden is still low and there is the greatest chance for cure."
            ></DiagnosisCard>
            <DiagnosisCard 
              heading="Life-changing"
              img={lifeChanging}
              content="We will deliver novel tests on our immunogenomics platform which will save lives, 
            enrich quality of life and provide peace of mind to patients and their loved ones."
            ></DiagnosisCard>
          </div>
        </div>
        <div className="solution-wrapper" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="2000">
          <div className="p-page">
            <h2 className="text-yellow">Solution</h2>
            <div className="flex flex-column gap-4 col-lg-6">
              <div className="solution-card" data-aos="fade-up" data-aos-easing="ease-in" data-aos-duration="2000">
                <p className="text-bold">Real-time Earlier Signal Detection</p>
                <p className="text-grey">Our immune system rapidly reacts to threats, triggering an internal amplification structure that causes changes to RNA expression levels within immune cells. </p>
              </div>
              <div className="solution-card" data-aos="fade-up" data-aos-easing="ease-in" data-aos-duration="2000">
                <p className="text-bold">Real-Time Surveillance</p>
                <p className="text-grey">Real-time surveillance of gene expression of monocytes and lymphocytes obtained from a patient enables the detection of immune-response signal changes that are caused by (i) intrinsic inter-individual variability, e.g. gender, genetic/ethnic background (ii) the body’s response to foreign threats such as cancer.</p>
              </div>
              <div className="solution-card" data-aos="fade-up" data-aos-easing="ease-in" data-aos-duration="2000">
                <p className="text-bold">Generative AI Machine Learning</p>
                <p className="text-grey">Applying a combination of bioinformatics, machine learning and biostatistical approaches, we have developed sophisticated generative AI algorithms to address and predict complex biological problems associated with high dimensionality and population, tumor, and outcome heterogeneity with complex or structured endpoints.</p>
              </div>
              <Link data-aos="fade-up" data-aos-easing="ease-in" data-aos-duration="2500" to={ROUTES.immunisAISolution} className="btn cta">Learn more</Link>
            </div>
          </div>
        </div>
        <div className="email-wrapper p-page border-down flex justify-content-center align-items-center" id="connect-with-us">
          <Formik initialValues={initialValues} validationSchema={ValidationSchema} onSubmit={handleFormSubmit}>
            {({ values, errors }) => (
              <Form className="col-lg-6 mb-5" data-aos="zoom-out" data-aos-easing="ease-in" data-aos-duration="2000">
                <h2 className="text-gradient text-center mb-6">
                  Get connected and receive the latest news about Immunis.AI
                </h2>
                <div className="row ">
                  <div className="col-12 col-lg-6 mb-3">
                    <label htmlFor="first_name">First name</label>
                    <input type="text" name="first_name" id="first_name" placeholder="John" className="w-100" required />
                    {values.first_name && errors.first_name && <p className="text-red">{errors.first_name}</p>}
                  </div>
                  <div className="col-12 col-lg-6 mb-3">
                    <label htmlFor="last_name">Last name</label>
                    <input type="text" name="last_name" id="last_name" placeholder="Doe" className="w-100" required />
                    {values.last_name && errors.last_name && <p className="text-red">{errors.last_name}</p>}
                  </div>
                </div>
                <div className="col-12 mb-5">  
                  <label htmlFor="email">Email</label>
                  <input type="text" name="email" id="email" placeholder="email@domain.com" className="w-100" required />
                  {values.email && errors.email && <p className="text-red">{errors.email}</p>}
                </div>
                <div className="col-12 flex justify-content-center">
                  <button className="btn cta" type="submit">Send</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default ImmunisLandingPageComponent
