interface cardProps {
  img: string
  heading: string
  content: string
}
const DiagnosisCard = (props: cardProps) => {
  const data = props
  return (
    <>
      <div className="flex justify-start rounded-none card p-4">
        <div className="py-3 DagnosiscardImage">
          <div className="flex justify-start">
            <img src={data?.img} />
          </div>
        </div>
        <p className="pY-3 text-bold">{data.heading}</p>
        <p className="text-grey">{data.content}</p>
      </div>
    </>
  )
}
export default DiagnosisCard
